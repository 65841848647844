import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import SchemaOrg from "./schema-org"
/* import config from "../../../config/website"
import defaultMetaImage from "../../../static/images/metaImage.jpg" */
import defaultMetaImage from "../../../static/images/ogdef.jpg"
/* import gothicFont from "./../../../static/fonts/goudymediaeval-400-regular.woff2" */

function SEO({
  siteMetadata: seo,
  title = seo.title,
  slug,
  description = seo.description,
  metaImage,
  image = `${seo.canonicalUrl}${metaImage || defaultMetaImage}`,
  withoutBread,
  breadName,
  blockIndexing,
}) {
  let url = slug ? `${seo.canonicalUrl}${slug}` : seo.canonicalUrl

  return (
    <>
      <Helmet>
        <html lang="en" />
        <link rel="canonical" href={url} />
        {/*         <link
          rel="preload"
          href={gothicFont}
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        /> */}

        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        {blockIndexing && <meta name="robots" content="noindex"></meta>}

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {/*         {isBlogPost ? <meta property="og:type" content="article" /> : null} */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="fb:app_id" content={seo.social.fbAppID} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.social.twitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/*         <meta name="twitter:image:alt" content={imageAltMax420} /> */}
        <meta name="twitter:site" content={seo.social.twitter} />
      </Helmet>
      <SchemaOrg
        author={seo.author}
        canonicalUrl={seo.canonicalUrl}
        defaultTitle={seo.title}
        description={description}
        image={image}
        organization={seo.organization}
        title={title}
        url={url}
        withoutBread={withoutBread}
        breadName={breadName}
      />
    </>
  )
}

function SEOWithQuery(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          canonicalUrl
          image
          author {
            name
          }
          organization {
            name
            url
            logo
          }
          social {
            twitter
            fbAppID
          }
        }
      }
    }
  `)
  return <SEO siteMetadata={siteMetadata} {...props} />
}

SEOWithQuery.propTypes = {
  withoutBread: PropTypes.bool,
}

SEOWithQuery.defaultProps = {
  withoutBread: false,
}

export default SEOWithQuery
