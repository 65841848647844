import * as React from "react"
import { Helmet } from "react-helmet"

export default React.memo(
  ({
    author,
    canonicalUrl,
    defaultTitle,
    description,
    image,
    organization,
    title,
    url,
    withoutBread,
    breadName,
  }) => {
    const baseSchema = [
      {
        "@context": "http://schema.org",
        "@type": "WebApplication",
        "@id": url,
        url,
        name: organization.name,
        alternateName: "MTG Deck Builder",
        applicationCategory: "GameApplication",
        operatingSystem: "All",
        logo: {
          "@context": "http://schema.org",
          "@type": "ImageObject",
          url: organization.logo,
          height: 512,
          width: 512,
        },
        offers: {
          "@type": "Offer",
          price: "0",
        },
      },
    ]

    const schema = withoutBread
      ? baseSchema
      : [
          ...baseSchema,
          {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@id": canonicalUrl,
                  name: "Home",
                  image,
                },
                name: "Home",
              },
            ],
          },
          {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 2,
                item: {
                  "@id": url,
                  name: breadName,
                  image,
                },
                name: breadName,
              },
            ],
          },
        ]

    return (
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    )
  }
)
