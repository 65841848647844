import React, { useContext } from "react"
import styled from "styled-components"
import { useToasts } from "react-toast-notifications"
import { navigate, Link } from "gatsby"

import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import ButtonPlain from "./ButtonPlain"

const handleLogout = (faunaQueryFun, sessionContext, addToast) => {
  faunaQueryFun()
    .then(res => {
      addToast("Logout successful", { appearance: "success" })
      sessionContext.dispatch({ type: "logout", data: null })
      navigate("/dashboard")
    })
    .catch(e => {
      /*       console.log("handleLogout -> e", e) */
      if (e.error) {
        addToast(e.error, { appearance: "error" })
      } else {
        addToast("Oops, something went wrong", { appearance: "error" })
      }
    })
}

const Profile = () => {
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  /*   console.log("Profile -> user", user) */
  const { addToast } = useToasts()

  return (
    <WrapperProfile>
      <Link to="/mtg-deck-builder/">
        <ButtonPlain
          key={"Home"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => navigate("/")}
          buttonText={"Home"}
          opacity={1}
          padding="0rem 2rem"
          minWidth="10rem"
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="2rem"
        />
      </Link>
      <Link to="/decks/">
        <ButtonPlain
          key={"Decks"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => navigate("/decks/")}
          buttonText={"Decks"}
          opacity={1}
          padding="0rem 2rem"
          minWidth="10rem"
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="2rem"
        />
      </Link>
      <Link to="/mtg-deck-builder/">
        <ButtonPlain
          key={"Builder"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => navigate("/mtg-deck-builder/")}
          buttonText={"Builder"}
          opacity={1}
          padding="0rem 2rem"
          minWidth="10rem"
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="2rem"
        />
      </Link>
      {user?.tier === "silver" || user?.tier === "rare" ? (
        <Link to="/mtg-deck-builder/">
          <ButtonPlain
            key={"My Decks"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() => navigate("/dashboard/my-decks/")}
            buttonText={"My Decks"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="10rem"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="1.8rem"
            borderRadius="2rem"
          />
        </Link>
      ) : null}
      {user?.tier === "silver" || user?.tier === "rare" ? (
        <Link to="/mtg-deck-builder/">
          <ButtonPlain
            key={"Feed"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() => navigate("/dashboard/lgs/")}
            buttonText={"Feed"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="10rem"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="1.8rem"
            borderRadius="2rem"
          />
        </Link>
      ) : null}

      {user?.tier === "silver" || user?.tier === "rare" ? (
        <Link to="/mtg-deck-builder/">
          <ButtonPlain
            key={"Favorites"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() => navigate("/dashboard/favorite-decks/")}
            buttonText={"Favorites"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="10rem"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="1.8rem"
            borderRadius="2rem"
          />
        </Link>
      ) : null}
      {user?.tier === "silver" || user?.tier === "rare" ? (
        <Link to="/mtg-deck-builder/">
          <ButtonPlain
            key={"Following"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() => navigate("/dashboard/following/")}
            buttonText={"Following"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="10rem"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="1.8rem"
            borderRadius="2rem"
          />
        </Link>
      ) : null}
      <Link to="/mtg-deck-builder/">
        <ButtonPlain
          key={"Sub"}
          hasSimpleLogic={true}
          handleSimpleOnClick={() => navigate("/dashboard/subscribe/")}
          buttonText={"Sub"}
          opacity={1}
          padding="0rem 2rem"
          minWidth="10rem"
          paddingTop="0.2rem"
          lineHeight="1.6"
          letterSpacing="0.25rem"
          fontSize="1.8rem"
          borderRadius="2rem"
        />
      </Link>
      {user ? (
        <Link to="/mtg-deck-builder/">
          <ButtonPlain
            key={"Profile"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() => navigate("/dashboard/profile/")}
            buttonText={"Profile"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="10rem"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="1.8rem"
            borderRadius="2rem"
          />
        </Link>
      ) : null}
      {user ? (
        <Link to="/mtg-deck-builder/">
          <ButtonPlain
            key={"Log out"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() =>
              handleLogout(faunaQueries.logoutAll, sessionContext, addToast)
            }
            buttonText={"Log out"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="10rem"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="1.8rem"
            borderRadius="2rem"
          />
        </Link>
      ) : (
        <Link to="/mtg-deck-builder/">
          <ButtonPlain
            key={"Login"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() => navigate("/dashboard/login/")}
            buttonText={"Login"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="10rem"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="1.8rem"
            borderRadius="2rem"
          />
        </Link>
      )}
    </WrapperProfile>
  )
}

export default Profile

const WrapperProfile = styled.div`
  display: flex;
  flex-wrap: wrap;
  /*   justify-content: space-between;
  align-items: center;
  align-content: center; */
  justify-content: center;
  background: radial-gradient(#555, #111);
  padding: 2rem 2rem 1rem 2rem;
`

const Section = styled.div`
  /* flex-grow: 1; */
  /*   justify-content: space-between;
  align-items: center;
  align-content: center;
  background: radial-gradient(#555, #111);
  padding: 1rem; */
`
